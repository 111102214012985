// @import '@/assets/css/components/select.scss';
@import '@/assets/css/components/tab.scss';

#leads {
  /deep/ .el-select {
    height: 60px !important;
    width: 300px;
    background: none;
    border-right: 1px $black solid;
  }
  .item_header {
    padding-left: 0 !important;
  }
  .btn_container {
    display: flex;
    li {
      border-radius: 0;
      background: none;
      border: none;
      height: 60px;
      border-left: 1px $black solid;
      line-height: 60px;
      margin: 0 auto;
      padding: 0 25px;
      font-size: 16px;
      font-weight: 300;
      color: $yellow1;
      border-bottom: 2px $yellow1 solid;
      box-sizing: border-box;
      cursor: pointer;
    }
    .is-plain {
      color: $white;
      border-bottom: none;
    }
  }
  .table_content {
    .table_box {
      margin-bottom: 33px;
    }
  }
}

@media (max-width: 550px) {
  #leads {
    .table_content {
      .tab li {
        padding: 10px;
      }
    }
  }
}
